import React, { useState } from "react";
import { Modal, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { editStrategy } from "../../../redux/new-insight/actions";
import StrategyLabels from "../create-strategy/StrategyLabels";
import StrategyTags from "../create-strategy/StrategyTags";
import styles from "../../../styles/Components/new-insight/create-strategy/CreateStrategyForm.module.css";

function EditStrategyModal({ show, setShow, strategy }) {
  const {
    main: { userData },
    newInsightState: { editStrategyStatus },
  } = useSelector((state) => state);
  const [form] = Form.useForm();
  const [isCustomTag, setIsCustomTag] = useState(false);

  const dispatch = useDispatch();

  const handleTagChange = (value) => {
    setIsCustomTag(value === "Custom");
    form.setFieldsValue({ strategy_tag: value === "Custom" ? "" : value });
  };
  const closeModal = () => setShow(false);

  const handleSubmit = async () => {
    if (editStrategyStatus === "loading") return;
    form
      .validateFields()
      .then(async (values) => {
        const allFields = Object.keys(values);
        let changedFields = [];
        let changedValues = [];

        allFields.forEach((f) => {
          if (values[f] !== strategy[f]) {
            changedFields.push(f);
            changedValues.push(values[f]);
          }
        });

        if (changedFields.length === 0) {
          closeModal();
          return;
        }

        // Dispatch the edit strategy action and wait for completion
        try {
          await dispatch(
            editStrategy(
              userData["custom:custom_username"],
              userData?.email,
              strategy?.createdDate,
              changedFields,
              changedValues,
            ),
          );
          closeModal(); // Close the modal after the dispatch completes
        } catch (error) {
          console.error("ErrorComponent updating strategy:", error);
        }
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
      });
  };

  const handleNoLeadingWhitespace = (e, fieldName) => {
    const { value } = e.target;
    form.setFieldsValue({
      [fieldName]: value.replace(/^\s+/, ""),
    });
  };

  return (
    <Modal
      centered
      open={show}
      onCancel={closeModal}
      onOk={handleSubmit}
      okText="Save"
      okButtonProps={{ loading: editStrategyStatus === "loading" }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          strategy_name: strategy.strategy_name,
          strategy_tag: strategy.strategy_tag,
          strategy_label: strategy.strategy_label,
        }}
        className={styles.container}
      >
        <Form.Item
          label="Name"
          name="strategy_name"
          rules={[
            { required: true, message: "Strategy name is required" },
            { min: 3, message: "Strategy name must be at least 3 characters" },
          ]}
        >
          <Input
            placeholder="Enter name"
            onChange={(e) => handleNoLeadingWhitespace(e, "strategy_name")}
          />
        </Form.Item>

        <Form.Item label="Label" name="strategy_label">
          <StrategyLabels
            defaultValue={strategy.strategy_label}
            onChange={(value) => form.setFieldsValue({ strategy_label: value })}
          />
          {/*<Input*/}
          {/*  placeholder="Enter strategy label"*/}
          {/*  onChange={(e) => handleNoLeadingWhitespace(e, "strategy_label")}*/}
          {/*/>*/}
        </Form.Item>

        <Form.Item
          label="Tag"
          name="strategy_tag"
          className={styles.tagContainer}
        >
          <StrategyTags
            defaultValue={strategy.strategy_tag}
            handleTagChange={handleTagChange}
          />

          {isCustomTag && (
            <Input
              placeholder="Enter custom tag"
              onChange={(e) =>
                form.setFieldsValue({ strategy_tag: e.target.value })
              }
            />
          )}
          {/*<Input*/}
          {/*  placeholder="Enter tag"*/}
          {/*  onChange={(e) => handleNoLeadingWhitespace(e, "strategy_tag")}*/}
          {/*/>*/}
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditStrategyModal;
