import { Button, Table, Typography } from "antd";
import styles from "../../../styles/Components/tables/portfolioTable.module.sass";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import React, { useState } from "react";
import useServiceStatus from "../../../hooks/useServiceStatus";
import {
  createWatchList,
  deleteWatchlist,
  modifyWatchlist,
} from "../../../redux/new-insight/actions";
import { IoNotifications, IoNotificationsOff } from "react-icons/io5";
import ServiceActivationModal from "../../../shared/service-activation/ServiceActivationModal";
import { formatPrice } from "../../../utility/helper";

const { Text } = Typography;

function TrendsTable() {
  const {
    newInsightState: {
      overallStatistics: { all_important_data: trendsData = [] },
      getInitialDataLoading,
      watchlists,
      watchlistStatus,
      createWatchlistStatus,
      modifyWatchListStatus,
    },
    main: { userData },
  } = useSelector((state) => state);

  const [showServiceActivationModal, setShowServiceActivationModal] =
    useState(false);

  const [targetCoin, setTargetCoin] = useState("");

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const sortValue = searchParams.get("sortBy") || "tops";
  const percentValue = searchParams.get("isPercent");
  const isPercentView =
    percentValue === "true" ? true : percentValue !== "false";

  const isActive = useServiceStatus("insight");
  const handleWatchlistActions = (isCoinInWatchlist, coin) => {
    if (watchlistStatus === "loading") return;

    // Prevent user to create watchlist if insight service is not active
    if (!isActive) return setShowServiceActivationModal(true);

    setTargetCoin(coin);

    if (watchlists.length === 0) {
      return dispatch(
        createWatchList(
          userData["custom:custom_username"],
          userData?.email,
          [coin],
          false,
        ),
      ).finally(() => setTargetCoin(""));
    }

    const currentWatchlist = watchlists?.[0];
    const createdDate = currentWatchlist?.createdDate;
    const coins =
      typeof currentWatchlist?.list_of_coins === "string"
        ? JSON.parse(currentWatchlist?.list_of_coins?.replace(/'/g, '"'))
        : currentWatchlist?.list_of_coins;

    if (coins.length === 1 && isCoinInWatchlist) {
      return dispatch(
        deleteWatchlist(
          userData["custom:custom_username"],
          userData?.email,
          createdDate,
          false,
        ),
      ).finally(() => setTargetCoin(""));
    }

    const updatedCoinsList = isCoinInWatchlist
      ? coins.filter((c) => c !== coin)
      : [...coins, coin];
    dispatch(
      modifyWatchlist(
        userData["custom:custom_username"],
        userData?.email,
        createdDate,
        updatedCoinsList,
        false,
      ),
    ).finally(() => setTargetCoin(""));
  };

  const displayTrendsData = [...trendsData].sort((a, b) => {
    switch (sortValue) {
      case "tops":
        return a.market_cap_rank - b.market_cap_rank; // Ascending by market rank
      case "gainers":
        return b.market_cap_change_24h - a.market_cap_change_24h; // Descending by market cap change
      case "losers":
        return a.market_cap_change_24h - b.market_cap_change_24h; // Ascending by market cap change
      default:
        return 0; // No sorting for unknown sortValue
    }
  });

  const columns = [
    {
      title: "#",
      dataIndex: "market_cap_rank",
      key: "market_cap_rank",
      align: "center",
      width: 15,
      render: (text) => (
        <span style={{ color: "#0C4CFC", fontFamily: "Poppins-Medium" }}>
          #{text}
        </span>
      ),
    },
    {
      title: "Coin",
      dataIndex: "symbol",
      key: "symbol",
      align: "center",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={record.image}
            alt={record.name}
            style={{ width: 24, height: 24, marginRight: 8 }}
          />
          <span>{text.toUpperCase()}</span>
        </div>
      ),
    },
    {
      title: "Current Price",
      dataIndex: "current_price",
      key: "current_price",
      align: "center",
      render: (price, record) => {
        const displayData = isPercentView
          ? record.price_change_percentage_24h
          : record.price_change_24h;
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <span>${formatPrice(price, 4)}</span>
            <Text type={displayData > 0 ? "success" : "danger"}>
              {isPercentView
                ? `${displayData.toFixed(2)}%`
                : formatPrice(displayData, 2)}
            </Text>
          </div>
        );
      },
      sorter: (a, b) =>
        b.price_change_percentage_24h - a.price_change_percentage_24h,
    },
    {
      title: "Market Cap",
      dataIndex: "market_cap",
      key: "market_cap",
      align: "center",
      render: (cap, record) => {
        const value = isPercentView
          ? record.market_cap_change_percentage_24h
          : record.market_cap_change_24h;
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <span>${(cap / 1_000_000_000).toFixed(2)}B</span>
            <Text type={value >= 0 ? "success" : "danger"}>
              {isPercentView ? `${value.toFixed(2)}%` : formatPrice(value, 2)}
            </Text>
          </div>
        );
      },
      sorter: (a, b) => b.market_cap - a.market_cap,
    },
    {
      title: "Social Sentiment",
      dataIndex: "google_trends_sentiment",
      key: "google_trends_sentiment",
      align: "center",
      render: (text, record) => {
        const status = record?.google_trends_interest_change;
        const change =
          typeof status === "number" ? status?.toFixed(2) + "%" : status;
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Text
              type={
                text === "Positive"
                  ? "success"
                  : text === "Negative"
                  ? "danger"
                  : ""
              }
            >
              {text}
            </Text>
            {typeof status === "number" && (
              <Text type={change >= 0 ? "success" : "danger"}>{change}</Text>
            )}
          </div>
        );
      },
    },
    {
      title: "Geopolitical Risk",
      dataIndex: "geopolitical_risk",
      key: "geopolitical_risk",
      align: "center",
      render: (text, record) => {
        const riskStatus = text === "False" ? "Safe" : "Risky";
        return (
          <Text
          // type={text === "False" ? "success" : "danger"}
          >
            {riskStatus}
          </Text>
        );
      },
    },
    {
      title: "Notification",
      key: "notification",
      align: "center",
      render: (_, record) => {
        const symbol = record.symbol.toUpperCase();
        const isCoinInList = watchlists?.[0]?.list_of_coins?.includes(symbol); // Check if the coin is in the list

        return (
          <Button
            className={styles.buyNow}
            disabled={watchlistStatus === "loading"}
            loading={
              targetCoin === symbol
                ? createWatchlistStatus === "loading" ||
                  modifyWatchListStatus === "loading"
                : false
            }
            onClick={() => handleWatchlistActions(isCoinInList, symbol)}
          >
            {isCoinInList ? (
              <IoNotificationsOff /> // Show IoNotificationsOff if in the list
            ) : (
              <IoNotifications /> // Show IoNotifications if not in the list
            )}
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Table
        className={styles.table}
        columns={columns}
        dataSource={displayTrendsData}
        loading={getInitialDataLoading}
        scroll={{ x: 320 }}
      />

      {showServiceActivationModal && (
        <ServiceActivationModal
          service={"insai"}
          show={showServiceActivationModal}
          setShow={setShowServiceActivationModal}
        />
      )}
    </>
  );
}

export default TrendsTable;
