import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { Button, Checkbox } from "antd";
import styles from "../../styles/shared/service-activation/ActivateServiceByCredit.module.css";
import DisplayCredit from "../../components/mini-app/shared/DisplayCredit";
import { FaCaretRight } from "react-icons/fa";
import DisplayActivationWithCreditResponse from "./DisplayActivationWithCreditResponse";
import { calculateOffPrice } from "../../utility/helper";
import ActivationDurationOptions from "./ActivationDurationOptions";
import { activateServiceWithCredit } from "../../redux/pricing-plan/action";
import {
  renderServiceActivationMode,
  servicesCost,
} from "../../utility/service-activation";
import useServiceStatus from "../../hooks/useServiceStatus";

function ActivateServiceByCredit({ service, setStep }) {
  const {
    profile: { profileData },
    pricingPlan: { activateServiceWithCreditStatus, pricingPlan },
    main: { userData },
  } = useSelector((state) => state);

  const isAlertActive = useServiceStatus("alert");
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const additionalCost = isChecked
    ? pricingPlan["alert"] || servicesCost["alert"]
    : 0;

  const cost = (pricingPlan[service] || servicesCost[service]) + additionalCost;
  const offPercentage =
    pricingPlan["yearly_discount"] || servicesCost["yearly_discount"];

  const maximumDuration = Math.trunc(profileData?.credit / 100 / cost);

  const showAlertSuggestion = !isAlertActive && service !== "alert";
  console.log(showAlertSuggestion, isAlertActive);

  const [selectedDuration, setSelectedDuration] = useState(1);
  const [content, setContent] = useState("selectDuration");

  const dispatch = useDispatch();
  const activateServiceByCredit = () => {
    if (activateServiceWithCreditStatus === "loading") return;

    const amount =
      selectedDuration > 1
        ? calculateOffPrice(offPercentage, cost, selectedDuration, "price")
        : cost;

    const redirectUrl = window.location.href;
    dispatch(
      activateServiceWithCredit(
        userData?.["custom:custom_username"],
        userData?.email,
        renderServiceActivationMode(service),
        `${amount}`,
        redirectUrl,
        redirectUrl,
      ),
    );
    setStep(3);
    setContent("result");
  };

  return (
    <div>
      {content === "selectDuration" && (
        <>
          <DisplayCredit style={{ marginBottom: "1rem" }} />
          {showAlertSuggestion && (
            <Checkbox onChange={handleChange} style={{ marginBottom: "1rem" }}>
              <span style={{ fontFamily: "Poppins-Regular" }}>
                Receive alerts for just an additional $
                {pricingPlan["alert"] || servicesCost["alert"]}
              </span>
            </Checkbox>
          )}
          <p className={styles.question}>
            <FaCaretRight />
            How many months would you like to activate this service for?
          </p>
          <ActivationDurationOptions
            service={service}
            selectedDuration={selectedDuration}
            setSelectedDuration={setSelectedDuration}
            activationType={"credit"}
            additionalCost={additionalCost}
          />
          <div className={styles.submitBtnContainer}>
            <Button
              className={styles.submitBtn}
              loading={activateServiceWithCreditStatus === "loading"}
              disabled={!selectedDuration || selectedDuration > maximumDuration}
              onClick={activateServiceByCredit}
            >
              Submit
            </Button>
          </div>
        </>
      )}

      {content === "result" && (
        <DisplayActivationWithCreditResponse
          lowCredit={false}
          changeContent={setContent}
          setStep={setStep}
        />
      )}
    </div>
  );
}

export default ActivateServiceByCredit;
