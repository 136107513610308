import moment from "moment";
import alert from "../components/alerts/Alert";
import Alert from "../components/alerts/Alert";
import PoolData from "../pages/Auth/AWSConfig/PoolData";
import { Modal } from "antd";
import { FaCrown, FaRegCopy } from "react-icons/fa";
import { RiErrorWarningLine } from "react-icons/ri";
import { notify } from "./Toastify/toastify";
import { servicesActivationMode } from "./data";

export const timeMoment = (time) => {
  const date = moment(time);
  return date.fromNow();
};

export function formatActionDate(str) {
  const now = new Date();
  const date = new Date(str);
  const diff = now - date;

  if (now.getDate() - date.getDate() === 1) return "Yesterday";

  // If the difference is less than 1 day, show hours and minutes
  if (diff < 86400000) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }`;
  }

  // If the difference is equal to 1 day, show "yesterday"
  if (diff === 86400000) {
    return "Yesterday";
  }

  // If the difference is more than 1 day, show the number of days ago
  const days = Math.floor(diff / 86400000);
  return `${days} days ago`;
}

export const currencyFormatter = (num, percentage) => {
  if (percentage) {
    return Math.sign(num) * Math.abs(num).toFixed(2) + "%";
  }
  if (Math.abs(num) > 99999999) {
    return Math.sign(num) * (Math.abs(num) / 1000000000).toFixed(2) + "B";
  } else if (Math.abs(num) > 999999) {
    return Math.sign(num) * (Math.abs(num) / 1000000).toFixed(2) + "M";
  } else if (Math.abs(num) > 999) {
    return Math.sign(num) * (Math.abs(num) / 1000).toFixed(2) + "K";
  }
  return Math.sign(num) * Math.abs(num).toFixed(2);
};

export function convertToTitleCase(str) {
  const changeStr =
    str === "pricing"
      ? (str = "pricing-&-billing")
      : str === "insight"
      ? (str = "insights")
      : str === "ai-assistant"
      ? "aI-assistant"
      : str;

  // Split the string into words using the hyphen as a separator
  const words = changeStr.split("-");

  // Capitalize the first letter of each word using map()
  const titleCaseWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the words back together with a space and return the final string
  return titleCaseWords.join(" ");
}

export function sortData(sections, data) {
  const sectionData = [];

  sections.forEach((section) => {
    if (data[section].length) {
      sectionData.push(data[section][0]);
    }
  });

  sectionData.sort(
    (a, b) => new Date(b["action-datetime"]) - new Date(a["action-datetime"]),
  );
  return sectionData;
}

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export function formatDate(date) {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-") +
    " " +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(":")
  );
}

export const days_between = (date1, date2) => {
  if (date2 < date1) {
    return "Expired";
  }
  // The number of milliseconds in one day
  const ONE_DAY = 1000 * 60 * 60 * 24;

  // Calculate the difference in milliseconds
  const differenceMs = Math.abs(date1 - date2);

  // Convert back to days and return
  if (Math.round(differenceMs / ONE_DAY) > 1) {
    return `${Math.round(differenceMs / ONE_DAY)} days left`;
  }

  return `${Math.round(differenceMs / ONE_DAY)} day left`;
};

export const checkEmailException = (email) => {
  const reformedEmail = email.toLowerCase();
  switch (reformedEmail) {
    case "hadisematloob@yahoo.com":
      return "Hadisematloob@yahoo.com";
    case "amir.ashtari@unipd.it":
      return "Amir.ashtari@unipd.it";
    case "m.khalid.ateeq@Gmail.com":
      return "M.Khalid.Ateeq@Gmail.com";
    default:
      return reformedEmail;
  }
};

export const suggestFeatures = function (answer1, answer2, answer3, answer4) {
  if (answer1 === "") answer1 = "sit in front";
  if (answer2 === "") answer2 = "dig";
  if (answer3 === "") answer3 = "yes I am";
  if (answer4 === "") answer4 = "evaluate";

  const all = `${answer1}, ${answer2}, ${answer3}, ${answer4}`;
  const featureDict = new Map([
    [
      "sit in front, dig, yes I am, evaluate",
      ["Insight", "AI assistant", "Trade simulator"],
    ],
    [
      "sit in front, dig, yes I am, rely on reports",
      ["Insight", "AI assistant"],
    ],
    [
      "sit in front, dig, Im not, evaluate",
      ["Insight", "AI assistant", "Trade simulator"],
    ],
    ["sit in front, dig, Im not, rely on reports", ["AI assistant", "Insight"]],
    [
      "sit in front, no time for that, yes I am, evaluate",
      ["AI assistant", "Trade simulator"],
    ],
    [
      "sit in front, no time for that, yes I am, rely on reports",
      ["AI assistant"],
    ],
    [
      "sit in front, no time for that, Im not, evaluate",
      ["AI assistant", "Trade simulator"],
    ],
    [
      "sit in front, no time for that, Im not, rely on reports",
      ["AI assistant"],
    ],
    [
      "alerted, dig, yes I am, evaluate",
      ["Alert system", "Insight", "Trade simulator"],
    ],
    ["alerted, dig, yes I am, rely on reports", ["Alert system", "Insight"]],
    [
      "alerted, dig, Im not, evaluate",
      ["Alert system", "Insight", "Trade simulator"],
    ],
    ["alerted, dig, Im not, rely on reports", ["Alert system", "Insight"]],
    [
      "alerted, no time for that, yes I am, evaluate",
      ["Alert system", "AI assistant", "Trade simulator"],
    ],
    [
      "alerted, no time for that, yes I am, rely on reports",
      ["Alert system", "AI assistant"],
    ],
    [
      "alerted, no time for that, Im not, evaluate",
      ["Alert system", "AI assistant", "Trade simulator"],
    ],
    [
      "alerted, no time for that, Im not, rely on reports",
      ["Alert system", "AI assistant"],
    ],
  ]);

  if (answer3 === "yes I am") {
    for (const [key, value] of featureDict) {
      if (!value.includes("Developer API")) {
        featureDict.set(key, [...value, "Developer API"]);
      }
    }
  }

  const features = featureDict.get(all);
  if (features) {
    return features;
  } else {
    return ["Invalid input. Please try again."];
  }
};

// TODO: NOTE: this function edited based on the telegram authentication
const clearLocalStorageAndSetLogoutTime = () => {
  localStorage.clear();
  localStorage.setItem("lastLogout", JSON.stringify(new Date()));
};
export const expiredTokenHandler = (message) => {
  if (localStorage.getItem("auth") !== "telegram") {
    clearLocalStorageAndSetLogoutTime();
    const user = PoolData.getCurrentUser();
    user?.signOut();
    window.location.reload();
  } else {
    const origin = window.location.origin;
    const prevPath = window.location.href;
    window.location.href = "/refresh-token";
    const telegramData = localStorage.getItem("telegramData");
    clearLocalStorageAndSetLogoutTime();
    localStorage.setItem("auth", "telegram");
    localStorage.setItem("telegramData", telegramData);
    localStorage.setItem("prevPath", `${origin}*${prevPath}`);
  }
};

export const checkAccountPlanHandler = (message) => {
  return Alert(null, message, "warning", {
    cancel: "Close",
    defeat: {
      text: "Buy Plan",
      value: "buyPlan",
    },
  });
};

export const actionErrorHandler = (message) => {
  return alert(null, message, "warning", {
    cancel: "Close",
  });
};

export const networkErrorHandler = () => {
  return alert("Something went wrong.", "Please try again later", "warning", {
    cancel: "Close",
  });
};

export const timeOutErrorHandler = () => {
  return alert(
    "Oops, something went wrong.",
    "Please come back later.",
    "warning",
    {
      cancel: "Close",
    },
  );
};

export const errorHandler = (code, message, noPricing = false) => {
  if (code === 504) {
    networkErrorHandler();
  } else if (code === 454) {
    timeOutErrorHandler();
  } else if (code === 450) {
    expiredTokenHandler(message);
  } else if ((code === 401 || code === 402 || code === 403) && !noPricing) {
    checkAccountPlanHandler(message);
  } else {
    actionErrorHandler(message);
  }
};

export function formatAssetPrice(num) {
  const number = Number(num);
  // Check if the absolute value of the number is less than 1
  if (Math.abs(number) < 1) {
    return number.toFixed(4);
  } else {
    if (number < 1000) {
      // If the number is less than 1000, display it as is with 2 decimal places
      return number.toFixed(4);
    } else if (number < 1000000) {
      // If the number is in thousands range, convert to K and display with 2 decimal places
      return (number / 1000).toFixed(4) + "K";
    } else if (number < 1000000000) {
      // If the number is in millions range, convert to M and display with 2 decimal places
      return (number / 1000000).toFixed(4) + "M";
    } else {
      // If the number is in billions range, convert to B and display with 2 decimal places
      return (number / 1000000000).toFixed(4) + "B";
    }
  }
}

export const formatAmount = (amount) => {
  const amountNumber = parseFloat(amount);
  if (amountNumber > 0) {
    if (amountNumber > 100) {
      return formatAssetPrice(amount);
    }
    const m = -Math.floor(Math.log10(amount) + 1);
    const numberOfDigits = Math.max(0, m); // Ensure numberOfDigits is non-negative
    return amountNumber.toFixed(numberOfDigits + 2);
  } else {
    return amountNumber;
  }
};

export const formatPrice = (number, decimals = 2) => {
  let num = Number(number);
  // check if the number is valid
  if (isNaN(num) || !isFinite(num)) {
    return "Invalid number";
  }
  // check if the number is negative and store the sign
  let sign = "";
  if (num < 0) {
    sign = "-";
    num = Math.abs(num); // make the number positive for formatting
  }
  // format the number with the appropriate suffix
  if (num >= 1e12) {
    return sign + (num / 1e12).toFixed(decimals) + "T"; // trillion
  } else if (num >= 1e9) {
    return sign + (num / 1e9).toFixed(decimals) + "B"; // billion
  } else if (num >= 1e6) {
    return sign + (num / 1e6).toFixed(decimals) + "M"; // million
  } else if (num >= 1e3) {
    return sign + (num / 1e3).toFixed(decimals) + "K"; // thousand
  } else {
    return sign + num.toFixed(decimals); // no suffix
  }
};

export const extractNumberFromString = (str) => {
  // Use regular expression to match any number with optional decimal places
  const match = str.match(/[-+]?[0-9]*\.?[0-9]+/);

  // Check if a match is found
  if (match) {
    // Convert the matched string to a number
    return parseFloat(match[0]);
  }

  // Return NaN if no match is found
  return NaN;
};

export function formatCustomDate(inputDate, showHourMin, showSec) {
  if (showHourMin) {
    if (showSec) {
      return moment(inputDate).format("MMM DD, YYYY HH:mm:ss");
    } else {
      return moment(inputDate).format("MMM DD, YYYY HH:mm");
    }
  } else {
    return moment(inputDate).format("MMM DD, YYYY");
  }
}

// Function to reorder the array based on specific IDs
export function reorderArray(arr) {
  const ids = ["cryptocrispy", "swyftx"];
  let reorderedArray = [];

  // First, add items with specified IDs at the beginning
  ids.forEach((id) => {
    const matchingItem = arr.find((item) => item.id === id);
    if (matchingItem) {
      reorderedArray.push(matchingItem);
    }
  });

  // Then, add the remaining items
  arr.forEach((item) => {
    if (!ids.includes(item.id)) {
      reorderedArray.push(item);
    }
  });

  return reorderedArray;
}

export const refreshLimit = (percent, price, tradeType, limitType) => {
  if (tradeType === "Sell") {
    return limitType === "stopLoss"
      ? price * (1 + percent / 100)
      : price * (1 - percent / 100);
  } else {
    return limitType === "stopLoss"
      ? price * (1 - percent / 100)
      : price * (1 + percent / 100);
  }
  // return ((100 - percent) / 100) * price;
};

export const areObjectsEqual = (obj1, obj2, properties) =>
  properties.every((property) => obj1[property] === obj2[property]);

export const allItemsMatch = (
  arr1,
  arr2,
  properties = ["symbol", "subcategory", "category", "feature"],
) => {
  return arr1.every((item1) =>
    arr2.some((item2) => areObjectsEqual(item1, item2, properties)),
  );
};

export const calculateDateDifference = (targetDate, justSuffix) => {
  // Parse the target date using moment
  const targetMoment = moment(targetDate);

  // Check if the target date is in the past
  if (targetMoment.isBefore(moment(), "day")) {
    return "expired";
  }

  // Calculate the difference in days
  const daysDifference = targetMoment.diff(moment(), "days");

  // Determine the appropriate suffix for "day" or "days"
  const daySuffix = daysDifference === 1 ? "day" : "days";

  // If the returnDaySuffix parameter is true, return only the daySuffix
  if (justSuffix) {
    return daySuffix + " left";
  }

  // Construct the result string
  return `${daysDifference}`;
};

export const calcDifferenceInDays = (endDate, startDate) => {
  const start = moment(startDate, "YYYY-MM-DD HH:mm:ss");
  const end = moment(endDate, "YYYY-MM-DD HH:mm:ss");

  const differenceInDays = end.diff(start, "days");

  return `${differenceInDays} ${differenceInDays > 1 ? " days" : "day"}`;
};

export const showLinkingWarning = () => {
  Modal.warning({
    title: "Warning",
    centered: true,
    content:
      "Changing your investment budget is not possible, as this virtual portfolio is linked with an exchange.",
    okText: "Close",
    okButtonProps: {
      style: {
        backgroundColor: "#2b5fec",
        borderColor: "#2b5fec",
      },
    },
    cancelButtonProps: {
      style: {
        display: "none",
      },
    },
    onOk: () => {
      Modal.destroyAll();
    },
  });
};

export const insightProFeatureWarning = (okAction) => {
  Modal.confirm({
    title: "Sorry",
    centered: true,
    content: "This feature is available only for Pro users.",
    cancelText: "Close",
    okText: "Buy Plan",
    okButtonProps: {
      style: {
        backgroundColor: "#2b5fec",
        borderColor: "#2b5fec",
      },
    },
    onOk: () => {
      // navigate("/pricing-billing");
      okAction();
    },
    onCancel: () => {
      Modal.destroyAll();
    },
  });
};

export const showWarningForSellCurrencies = (okAction, sellType) => {
  const message =
    sellType === "fiat"
      ? "You're about to sell all your fiat currency. This action is irreversible. Please confirm you want to proceed."
      : "You're initiating the sale of all your crypto assets. This cannot be undone. Ensure you review this action before continuing.";

  const okText = sellType === "fiat" ? "Confirm" : "Continue";

  Modal.confirm({
    title: "Attention",
    centered: true,
    content: message,
    cancelText: "Close",
    okText,
    okButtonProps: {
      style: {
        backgroundColor: "#2b5fec",
        borderColor: "#2b5fec",
      },
    },
    onOk: () => {
      return new Promise((resolve, reject) => {
        // Call the okAction and only resolve the promise after it's done
        okAction().then(resolve).catch(reject);
      });
    },
    onCancel: () => {
      Modal.destroyAll();
    },
  });
};

export const showUpgradeModal = (okAction) => {
  Modal.confirm({
    title: "Upgrade to Enterprise Plan",
    content:
      "Heads up! This feature is part of our Enterprise plan.  Ready to upgrade?",
    centered: true,
    icon: <FaCrown size={18} color={"#daa520"} />,
    okText: "Buy Plan",
    okButtonProps: {
      style: {
        backgroundColor: "#0C4CFC",
        borderColor: "#0C4CFC",
      },
    },
    onOk: () => {
      okAction(); // Call the okAction without returning a promise
      Modal.destroyAll(); // This will close the modal immediately
    },
    onCancel() {
      Modal.destroyAll();
    },
  });
};

export const showExchangeIpModal = (okAction, ip) => {
  const copyHandler = (ip, e) => {
    if (e) {
      e.stopPropagation();
    }
    navigator.clipboard.writeText(ip);
    notify(`Copied: ${ip} to clipboard.`, "success");
  };

  Modal.confirm({
    title: "Update IP",
    content: (
      <div>
        You need to update the IP in your exchange settings.
        <p className={"ipBox"}>
          {ip.map((i) => (
            <span key={i} className={"ip"} onClick={() => copyHandler(i)}>
              {i}
              <FaRegCopy className={"copyIcon"} />
            </span>
          ))}
          {/*<FaRegCopy className={"copyIcon"} onClick={copyHander} />*/}
        </p>
      </div>
    ),
    centered: true,
    icon: <RiErrorWarningLine size={22} color={"#0C4CFC"} />,
    okText: "Update IP",
    okButtonProps: {
      style: {
        backgroundColor: "#0C4CFC",
        borderColor: "#0C4CFC",
      },
    },
    onOk: () => {
      return new Promise((resolve, reject) => {
        // Call the okAction and only resolve the promise after it's done
        okAction().then(resolve).catch(reject);
      });
    },
    onCancel() {
      Modal.destroyAll();
    },
  });
};

export const convertRoutNameToTitle = (route) => {
  if (route.includes("bot")) {
    return "Bot_and_Automation";
  }

  if (route.includes("alert-system")) {
    return "Alert_System";
  }

  if (route.includes("ai-assistant")) {
    return "AI_Assistant";
  }

  if (route.includes("integration")) {
    return "Integration";
  }

  if (route.includes("dashboard")) {
    return "Dashboard";
  }

  if (route.includes("insight")) {
    return "Insight";
  }

  return route;
};

export const isUnique = (array, obj) => {
  return !array.some(
    (item) =>
      item.symbol === obj.symbol &&
      item.subcategory === obj.subcategory &&
      item.category === obj.category &&
      item.feature === obj.feature,
  );
};

export const transformObject = (inputObject) => {
  let transformedArray = [];

  // Function to transform and push items into the result array
  function transformAndPush(items, action) {
    if (items.length === 0) return;
    items.forEach((item) => {
      if (Object.keys(item).length > 0) {
        let transformedItem = {
          ...item,
          action: action,
        };
        transformedArray.push(transformedItem);
      }
    });
  }

  // Check and transform "Buy/Long Zone"
  if (inputObject && inputObject["Buy/Long Zone"]) {
    transformAndPush(inputObject["Buy/Long Zone"], "Buy");
  }

  // Check and transform "Sell/Short Zone"
  if (inputObject && inputObject["Sell/Short Zone"]) {
    transformAndPush(inputObject["Sell/Short Zone"], "Sell");
  }

  // Check and transform "Hold Zone"
  if (inputObject && inputObject["Hold Zone"]) {
    transformAndPush(inputObject["Hold Zone"], "Hold");
  }

  // Filter out empty arrays
  // transformedArray = transformedArray.filter((arr) => arr.length > 0);

  // console.log("transformed", transformedArray);
  return transformedArray;
};

export function customSort(arr) {
  const buySignals = arr
    .filter((item) => item?.TRADE_TYPE === "Buy")
    ?.sort((a, b) => b?.SIGNAL_STRENGTH - a?.SIGNAL_STRENGTH);
  const otherSignals = arr
    .filter((item) => item?.TRADE_TYPE !== "Buy")
    ?.sort((a, b) => b?.SIGNAL_STRENGTH - a?.SIGNAL_STRENGTH);
  return buySignals.concat(otherSignals);
}

export const formatUserData = (data) => {
  const { jwtToken: token, payload } = data;
  return {
    ...payload,
    sub: token,
    email_verified: true,
    iss: "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_X8ZvlAfzE",
    origin_jti: token,
    aud: "1pf2nms2247fik73t0rsju4s9h",
    event_id: token,
    token_use: "id",
    auth_time: 1716648521,
    exp: 1716655721,
    iat: 1716648521,
    "custom:about_us": "MediaOrNews",
    jti: token,
    systemData: {},
  };
};

export const formatAIMode = {
  only_ai: "Only AI",
  only_onchain: "Only on-chain",
  only_ta: "Only technical indicator",
  only_social: "Only social",
};

export const calculateOffPrice = (
  offPercentage,
  mainPrice,
  duration,
  returnedValue,
) => {
  const durationCost = duration * mainPrice;
  const offValue = (offPercentage / 12) * duration;
  const offeredPrice = (((offPercentage / 12) * duration) / 100) * durationCost;
  if (returnedValue === "off") {
    return offValue;
  } else if (returnedValue === "offeredPrice") {
    return offeredPrice;
  } else {
    return durationCost - offeredPrice;
  }
};

export const getActiveSubscriptions = (data) => {
  return Object.keys(data).filter((key) => {
    const value = data[key];
    return value !== null && !value?.cancel_at;
  });
};

export const getLastCanceledSubscription = (data) => {
  let maxKey = null;
  let maxCancelAt = -Infinity;

  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (value !== null && value.cancel_at) {
      if (value.cancel_at > maxCancelAt) {
        maxCancelAt = value.cancel_at;
        maxKey = key;
      }
    }
  });

  return maxKey || null;
};

export const getSpecificSubscription = (subscriptionsData, planType) => {
  const withPro = {};
  const withoutPro = {};

  Object.keys(subscriptionsData).forEach((key) => {
    if (key.includes("pro")) {
      withPro[key] = subscriptionsData[key];
    } else {
      withoutPro[key] = subscriptionsData[key];
    }
  });

  const sortOrder = ["yearly", "monthly", "daily"];

  const sortByOrder = (obj) => {
    const sortedObj = {};
    sortOrder.forEach((order) => {
      Object.keys(obj).forEach((key) => {
        if (key.startsWith(order)) {
          sortedObj[key] = obj[key];
        }
      });
    });
    return sortedObj;
  };

  if (planType === "pro") return sortByOrder(withPro);
  if (planType === "basic") return sortByOrder(withoutPro);
};

export const getAllfeatures = (data) => {
  let results = [];
  for (const ctg in data) {
    const subCategories = data[ctg];
    for (const subKey in subCategories) {
      const features = subCategories[subKey];
      for (const featureKey in features) {
        const featureData = features[featureKey];
        const updatedFeatureData = {
          ...featureData,
          feature: featureData.name,
        };
        const { category, subcategory, feature, description } =
          updatedFeatureData;
        const featureString = `${category}/${subcategory}/${feature}/${description}`;

        updatedFeatureData.featureString = featureString;

        results.push(updatedFeatureData);
      }
    }
  }

  return results;
};
