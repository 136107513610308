import React, { useState } from "react";
import { Dropdown, Button, message, Modal } from "antd";
import styles from "../../../styles/Components/new-insight/signals-section/StrategyDropdown.module.css";
import { IoIosArrowDown } from "react-icons/io";
import { HiPencil, HiTrash } from "react-icons/hi";
import {
  deleteStrategy,
  getStrategySignal,
  setCurrentStrategy,
} from "../../../redux/new-insight/actions";
import { useDispatch, useSelector } from "react-redux";
import CreateStrategyModalV2 from "../create-strategy/CreateStrategyModalV2";
import EditStrategyModal from "./EditStrategyModal";
import { useSearchParams } from "react-router-dom";

const StrategyDropdown = ({ data, title, loading, type }) => {
  const {
    main: { userData },
  } = useSelector((state) => state);
  const [showFeaturesModal, setShowFeaturesModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedStrategy, setSelectedStrategy] = useState({});
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();

  const confirmToDeleteStrategy = (date) => {
    setOpen(false);
    Modal.confirm({
      title: "Warning",
      centered: true,
      content: "Are you sure you want to delete this strategy?",
      okText: "Yes, I am",
      okButtonProps: {
        style: {
          backgroundColor: "#0c3fce",
          borderColor: "#0c3fce",
        },
      },
      onOk: async () => {
        await dispatch(
          deleteStrategy(
            userData["custom:custom_username"],
            userData?.email,
            date,
          ),
        );
        Modal.destroyAll();
      },
      onCancel: () => {
        Modal.destroyAll();
      },
    });
  };

  const handleEditStrategy = (e, data) => {
    e.stopPropagation();
    setOpen(false);
    setShowEditModal(true);
    setSelectedStrategy(data);
  };

  const getSignals = (date, item) => {
    // clear search params
    setSearchParams({});

    // store strategy data in the url
    searchParams.set("strategy", date);
    setSearchParams(searchParams);
    dispatch(setCurrentStrategy(item));

    dispatch(
      getStrategySignal(
        userData["custom:custom_username"],
        userData?.email,
        date,
        type === "system",
      ),
    );
  };

  const items = data.map((item, index) => {
    const {
      strategy_name: name,
      strategy_label: label = "",
      createdDate: date,
      strategy_tag: tag = "",
    } = item;

    return {
      key: index,
      label: (
        <div
          className={styles.strategyItem}
          onClick={() => getSignals(date, item)}
        >
          <div className={styles.strategyInfo}>
            <span className={styles.strategyName}>{name}</span>
            {label && <span className={styles.label}>{label}</span>}
            {tag && <span className={styles.tag}>{tag}</span>}
          </div>
          {type === "user" && (
            <div className={styles.strategyHandlers}>
              <span onClick={(e) => handleEditStrategy(e, item)}>
                <HiPencil size={14} />
              </span>
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  confirmToDeleteStrategy(item.createdDate);
                }}
              >
                <HiTrash size={14} />
              </span>
            </div>
          )}
        </div>
      ),
    };
  });

  const handleCreateClick = () => {
    message.destroy();
    setShowFeaturesModal(true);
  };

  const handleButtonClick = () => {
    if (type === "user" && data.length === 0) {
      message.info(
        <p style={{ marginBottom: 0 }}>
          No strategy available!{" "}
          <span
            style={{ color: "#0C4CFC", cursor: "pointer" }}
            onClick={handleCreateClick}
          >
            Create
          </span>{" "}
          the first one now.
        </p>,
      );
    }
  };

  return (
    <>
      {data.length > 0 ? (
        <Dropdown
          menu={{
            items,
          }}
          open={open}
          onOpenChange={setOpen}
          className={styles.dropdown}
          trigger="click"
        >
          <Button
            className={`${styles.btn} ${
              type === "system" ? styles.systemBtn : styles.userBtn
            }`}
          >
            {title} <IoIosArrowDown />
          </Button>
        </Dropdown>
      ) : (
        <Button
          onClick={handleButtonClick}
          loading={loading}
          className={`${styles.btn} ${
            type === "system" ? styles.systemBtn : styles.userBtn
          }`}
        >
          {title} <IoIosArrowDown />
        </Button>
      )}

      {showFeaturesModal && (
        <CreateStrategyModalV2
          show={showFeaturesModal}
          closeHandler={() => setShowFeaturesModal(false)}
          type={"selectFeature"}
        />
      )}
      {showEditModal && (
        <EditStrategyModal
          setShow={setShowEditModal}
          show={showEditModal}
          strategy={selectedStrategy}
        />
      )}
    </>
  );
};

export default StrategyDropdown;
