import { useSelector } from "react-redux";

const servicesKey = {
  insight: "insai",
  tradeSimulator: "trsim",
  bot: "mybot",
  aiAssistant: "myais",
  alert: "alert",
};

// That is a custom hook that receive service name and return services status (active or not)

// service argument can be: insight, tradeSimulator, bot, aiAssistant, alert,
function UseServiceStatus(service) {
  const {
    profile: { profileData },
  } = useSelector((state) => state);

  const isActive = profileData?.[`${servicesKey[service]}_active`] === "True";

  return isActive;
}

export default UseServiceStatus;
