import styles from "../../styles/Components/pricing-plan/Subscription.module.css";
import { FaListAlt, FaReceipt } from "react-icons/fa";
import React, { useState } from "react";
import PlanList from "../../pages/pricing-plan/PlanList";
import Billing from "../../pages/pricing-plan/Billing";
import PlansList from "./PlansList";
import PricingDiscountButton from "../../shared/PricingDiscountButton";

function Subscription() {
  const [activeTab, setActiveTab] = useState("subscription");

  const changeTabHandler = (e) => {
    const tabName = e.currentTarget.id;
    if (activeTab !== tabName) {
      setActiveTab(tabName);
    }
  };
  return (
    <div className={styles.container}>
      {/* <PricingDiscountButton /> */}
      <ul className={styles.tabs}>
        <li
          className={`${styles.tab} ${
            activeTab === "subscription" ? styles.active : ""
          }`}
          id={"subscription"}
          onClick={changeTabHandler}
        >
          <FaListAlt />
          {window.innerWidth > 480 ? "Subscription" : ""} Plans
        </li>
        <li
          className={`${styles.tab} ${
            activeTab === "billing" ? styles.active : ""
          }`}
          id={"billing"}
          onClick={changeTabHandler}
        >
          <FaReceipt />
          Billing
        </li>
      </ul>

      {activeTab === "subscription" && <PlansList />}
      {/*{activeTab === "subscription" && <PlanList />}*/}
      {activeTab === "billing" && <Billing />}
    </div>
  );
}

export default Subscription;
