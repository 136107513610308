export const servicesCost = {
  myais: 50,
  alert: 30,
  mybot: 25,
  insai: 80,
  trsim: 40,
  subscription: 19.99,
  subscription_aiSignal: 149.99,
  yearly_discount: 30,
  referral_credit: 2,
  referral_earn_percentage: 10,
  account_create_credit: 5,
  aiSignal: 160,
  miniAppAiSignals: 80,
};

export const servicesActivationMode = {
  // 'alert'
  // 'trsim'
  // aiSignal
  // 'insai
  // 'mybot'
  // 'myais'
  aiSignal: "myais_alert_insai_trsim",
  tradeSimulator: "trsim",
  insai: "insai",
  insights: "insai",
  bot: "mybot",
  myais: "myais",
  alert: "alert",
  mybot: "mybot",
  miniAppAiSignals: "myais_alert",
};

export const renderServiceActivationMode = (service, plusAlert = false) => {
  return `activate_${servicesActivationMode[service]}${
    plusAlert ? "_alert" : ""
  }`;
};
