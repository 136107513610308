import styles from "../../styles/shared/service-activation/ServiceActivationModal.module.css";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { IoArrowBackCircleOutline, IoWarningOutline } from "react-icons/io5";
import React, { useEffect, useState } from "react";
import ActivateServiceByCredit from "./ActivateServiceByCredit";
import { BsCaretRightFill, BsCreditCard } from "react-icons/bs";
import DisplayCredit from "../../components/mini-app/shared/DisplayCredit";
import ActivateServiceByLowCredit from "./ActivateServiceByLowCredit";
import {
  resetActivationWithCardData,
  resetActivationWithCreditData,
  resetActivationWithCryptoData,
} from "../../redux/pricing-plan/action";
import SubscriptionSettings from "./SubscriptionSettings";
import { FaUserFriends } from "react-icons/fa";
import ServiceActivationMethodsForLowCredit from "./ServiceActivationMethodsForLowCredit";
import ServiceActivationPaymentMethods from "./ServiceActivationPaymentMethods";
import ReferralSuggestions from "./ReferralSuggestions";
import { servicesCost } from "../../utility/service-activation";

function ServiceActivationModal({ show, setShow, service }) {
  const {
    profile: { profileData },
    pricingPlan: { pricingPlan },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [activationMethod, setActivationMethod] = useState(null);
  const [step, setStep] = useState(1);
  const credit = profileData?.credit / 100;
  const costs = pricingPlan?.[service] || servicesCost?.[service];

  const hasEnoughCredit = credit / costs >= 1;

  const closeModal = () => {
    setStep(1);
    setActivationMethod(null);
    setShow(false);
  };

  const selectActivationMethod = (e) => {
    const methodName = e.target.id;

    if (methodName) {
      setActivationMethod(methodName);
      setStep(2);
    }
  };

  const changeActivationMethod = (e) => {
    setActivationMethod(e.target.value);
    setStep(2);
  };

  useEffect(() => {
    if (show) {
      dispatch(resetActivationWithCreditData());
      dispatch(resetActivationWithCryptoData());
      dispatch(resetActivationWithCardData());
    }
  }, [dispatch, show]);

  return (
    <Modal
      open={show}
      onCancel={closeModal}
      centered
      footer={null}
      bodyStyle={{ paddingBottom: "3rem" }}
      maskClosable={false}
    >
      {step === 1 && (
        <>
          <div className={styles.title}>
            <IoWarningOutline size={22} color={"#e84118"} />
            <h3>This service is not active</h3>
          </div>
          <DisplayCredit style={{ marginBottom: "1rem" }} />
          <div className={styles.activationSuggestion}>
            <h4>
              <BsCaretRightFill />
              Just choose how you'd like to activate your service:
            </h4>
            <div className={styles.activationMethods}>
              {hasEnoughCredit && (
                <span
                  className={`${styles.methodBtn} ${
                    activationMethod === "credit" ? styles.selected : ""
                  }`}
                  id={"credit"}
                  onClick={selectActivationMethod}
                >
                  <BsCreditCard />
                  With Account Credit
                </span>
              )}
              {!hasEnoughCredit && (
                <ServiceActivationMethodsForLowCredit
                  service={service}
                  method={activationMethod}
                  onChange={changeActivationMethod}
                />
              )}
              <h4 className={styles.referralOption}>
                <FaUserFriends /> We recommend{" "}
                <span
                  className={styles.referLink}
                  id={"referrals"}
                  onClick={selectActivationMethod}
                >
                  referring
                </span>{" "}
                friends to earn more credits.
              </h4>
            </div>
          </div>
          {activationMethod && (
            <div className={styles.nextBtn} onClick={() => setStep(2)}>
              Next
            </div>
          )}
        </>
      )}

      {step > 1 && (
        <div className={styles.settingsContainer}>
          {step === 2 && (
            <div className={styles.backBtn} onClick={() => setStep(1)}>
              <IoArrowBackCircleOutline />
            </div>
          )}
          {activationMethod === "credit" && (
            <ActivateServiceByCredit service={service} setStep={setStep} />
          )}
          {activationMethod === "payment" && (
            <ServiceActivationPaymentMethods
              service={service}
              setStep={setStep}
            />
          )}
          {activationMethod === "referrals" && <ReferralSuggestions />}
          {activationMethod === "lowCredit" && (
            <ActivateServiceByLowCredit service={service} setStep={setStep} />
          )}
          {activationMethod === "subscribe" && (
            <SubscriptionSettings service={service} />
          )}
        </div>
      )}
    </Modal>
  );
}

export default ServiceActivationModal;
