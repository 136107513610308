import React, { useState } from "react";
import styles from "../../../styles/Components/alert-system/AlertSystem.module.sass";
import { Button, Dropdown, Menu, Spin } from "antd";
import { IoIosArrowDown, IoMdSettings } from "react-icons/io";
import { ImLocation2 } from "react-icons/im";
import { RiLockFill, RiMap2Fill } from "react-icons/ri";
import { timeZoneToCountryCode } from "../../../utility/data";
import { FaTelegramPlane } from "react-icons/fa";
import { useSelector } from "react-redux";
import Navbar from "../Navbar";
import ServiceActivationModal from "../../../shared/service-activation/ServiceActivationModal";
import useServiceStatus from "../../../hooks/useServiceStatus";

const alertFrequencyConverter = {
  "1H": "1 hour",
  "3H": "3 hours",
  "6H": "6 hours",
  "12H": "12 hours",
  "1D": "day",
  "4D": "twice a week",
  "7D": "weekly",
  "14D": "bi-weekly",
  "28D": "monthly",
};
const riskTakingAmountConverter = {
  1: "Very Cautious",
  2: "Cautious",
  3: "Balanced",
  4: "Adventurous",
  5: "Very Adventurous",
};

function AlertSystemSettingsReview({ data, setDisplayMode }) {
  const {
    alertSystem: { alertStatusLoading, telegramBotToken },
  } = useSelector((state) => state);

  const [timeZone, setTimeZone] = useState(0);
  const [showServiceActivationModal, setShowServiceActivationModal] =
    useState(false);

  const isServiceActive = useServiceStatus("alert");

  const date = new Date();
  const dateAsString = date.toString();
  const localTimeZone = dateAsString.match(/\(([^\)]+)\)$/)[1];
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZoneCode = timeZoneToCountryCode?.[timeZoneString]
    ? `${timeZoneToCountryCode[timeZoneString]}ST`
    : localTimeZone;

  const telegramLink = telegramBotToken
    ? `https://t.me/cryptocrispy_bot?start=${telegramBotToken}`
    : "https://t.me/cryptocrispy_bot";

  const changeTimeZoneHandler = (time) => {
    const date = new Date(time);
    if (timeZone === 0) {
      const options = { hour12: true, hour: "numeric", minute: "numeric" };
      return date.toLocaleTimeString(undefined, options);
    } else {
      // return `${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}`;
      return `${date.getUTCHours()}:${date.getUTCMinutes()}`;
    }
  };

  const timeZoneHandler = (e) => {
    setTimeZone(+e.key);
  };
  const menu = (
    <Menu
      className={styles.MenuClassName}
      onClick={timeZoneHandler}
      items={[
        {
          label: timeZoneCode,
          key: "0",
          icon: <ImLocation2 />,
        },
        {
          label: "UTC",
          key: "1",
          icon: <RiMap2Fill />,
        },
      ]}
    />
  );

  const handleSettingOnClick = () => {
    if (!isServiceActive) {
      setShowServiceActivationModal(true);
      return;
    }
    setDisplayMode("setting");
  };

  return (
    <>
      <div className={styles.overViewWrapper} style={{ position: "relative" }}>
        {alertStatusLoading && (
          <div className={styles.loader}>
            <Spin />
          </div>
        )}

        <div className={styles.overView}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <h4 className={styles.title} style={{ marginBottom: 0 }}>
              Alert System
            </h4>
            <div className={styles.timeZoneBtn} style={{ position: "unset" }}>
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                className={styles.dropDown}
              >
                <a href="nothing" onClick={(e) => e.preventDefault()}>
                  {timeZone === 0 ? (
                    <span>{timeZoneCode}</span>
                  ) : (
                    "UTC Time Zone"
                  )}
                  <IoIosArrowDown />
                </a>
              </Dropdown>
            </div>
          </div>
          <Navbar />

          <div className={styles.summary}>
            <div className={styles.summaryText}>
              <div>
                <p>
                  <span className={styles.settingsLabel}>Frequency:</span>{" "}
                  <span className={styles.desktopVersion}>
                    Alerts are sent every{" "}
                    <span style={{ color: "#0C4CFC" }}>
                      {alertFrequencyConverter[data?.alert_frequency]}
                    </span>{" "}
                    {data?.alert_frequency?.includes("H") ? (
                      <>
                        between
                        <span style={{ marginLeft: ".5rem", color: "#0C4CFC" }}>
                          {changeTimeZoneHandler(
                            data.start_time_interval_active,
                          )}
                        </span>{" "}
                        and
                        <span style={{ marginLeft: ".5rem", color: "#0C4CFC" }}>
                          {changeTimeZoneHandler(data.end_time_interval_active)}
                        </span>
                      </>
                    ) : (
                      <>
                        at{" "}
                        <span style={{ color: "#0C4CFC" }}>
                          {changeTimeZoneHandler(data?.prefered_alert_time)}
                        </span>
                      </>
                    )}{" "}
                    ({timeZoneCode}).{" "}
                  </span>
                  <span className={styles.mobileVersion}>
                    <span style={{ color: "#0C4CFC" }}>
                      {alertFrequencyConverter[data?.alert_frequency]}
                    </span>{" "}
                    alerts{" "}
                    {data?.alert_frequency?.includes("H") ? (
                      <>
                        (
                        <span style={{ color: "#0C4CFC" }}>
                          {changeTimeZoneHandler(
                            data.start_time_interval_active,
                          )}
                        </span>{" "}
                        -
                        <span style={{ color: "#0C4CFC" }}>
                          {changeTimeZoneHandler(data.end_time_interval_active)}
                        </span>{" "}
                        {timeZoneCode})
                      </>
                    ) : (
                      <>
                        at{" "}
                        <span style={{ color: "#0C4CFC" }}>
                          {changeTimeZoneHandler(data?.prefered_alert_time)}
                        </span>{" "}
                        {timeZoneCode}
                      </>
                    )}{" "}
                  </span>
                </p>
                <p>
                  <span className={styles.settingsLabel}>Risk Parameters:</span>{" "}
                  <span className={styles.desktopVersion}>
                    AI signals and risk parameters are based on a{" "}
                    <span style={{ color: "#0C4CFC" }}>
                      {riskTakingAmountConverter[data?.risk_taking_amount]}
                    </span>{" "}
                    risk appetite.
                  </span>
                  <span className={styles.mobileVersion}>
                    AI signals based on risk appetite (
                    <span style={{ color: "#0C4CFC" }}>
                      {riskTakingAmountConverter[data?.risk_taking_amount]}
                    </span>
                    ).
                  </span>
                </p>
                <p>
                  <span className={styles.settingsLabel}>
                    Portfolio Managements:
                  </span>{" "}
                  <span className={styles.desktopVersion}>
                    The watchdog for loss-making assets is{" "}
                    <span style={{ color: "#0C4CFC" }}>
                      {data?.asset_in_danger_watch === "Yes"
                        ? "active"
                        : "inactive"}
                    </span>{" "}
                    in virtual portfolio{" "}
                    <span style={{ color: "#0C4CFC" }}>#1</span>.{" "}
                    {data?.asset_in_danger_watch &&
                      data?.asset_in_danger_watch !== "No" && (
                        <>
                          A warning is issued when the balance decreases by{" "}
                          <span style={{ color: "#0C4CFC" }}>
                            {Math.abs(data?.balance_thresh)}%
                          </span>
                        </>
                      )}
                  </span>
                  <span className={styles.mobileVersion}>
                    Watchdog{" "}
                    <span style={{ color: "#0C4CFC" }}>
                      {data?.asset_in_danger_watch === "Yes"
                        ? "active"
                        : "inactive"}
                    </span>{" "}
                    in virtual portfolio{" "}
                    <span style={{ color: "#0C4CFC" }}>#1</span>.
                    {data?.asset_in_danger_watch &&
                      data?.asset_in_danger_watch !== "No" && (
                        <>
                          Alert at{" "}
                          <span style={{ color: "#0C4CFC" }}>
                            {Math.abs(data?.balance_thresh)}%
                          </span>{" "}
                          balance decrease
                        </>
                      )}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.settingBtns}>
          {/*<Button className={styles.discordBtn}>*/}
          {/*  <a*/}
          {/*    href={*/}
          {/*      "https://discord.com/oauth2/authorize?client_id=1233038760225144936&scope=bot"*/}
          {/*    }*/}
          {/*    target={"_blank"}*/}
          {/*    rel="noreferrer"*/}
          {/*  >*/}
          {/*    <FaDiscord />*/}
          {/*    {window.innerWidth > 600*/}
          {/*      ? "Get Alerts in Discord"*/}
          {/*      : "Discord Alerts"}*/}
          {/*  </a>*/}
          {/*</Button>*/}
          <Button className={styles.telegramBtn}>
            <a href={telegramLink} target={"_blank"} rel="noreferrer">
              <FaTelegramPlane />
              {window.innerWidth > 600
                ? "Get Alerts in Telegram"
                : "Telegram Alerts"}
            </a>
          </Button>
          <Button onClick={handleSettingOnClick} className={styles.reviseBtn}>
            {!isServiceActive && <RiLockFill />}
            {isServiceActive && <IoMdSettings />}
            Revise Settings
          </Button>
        </div>

        {showServiceActivationModal && (
          <ServiceActivationModal
            service={"alert"}
            show={showServiceActivationModal}
            setShow={setShowServiceActivationModal}
          />
        )}
      </div>
    </>
  );
}

export default AlertSystemSettingsReview;
