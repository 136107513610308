import styles from "../../styles/shared/service-activation/SubscriptionSettings.module.css";
import { FaCrown } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs } from "antd";
import {
  getCheckoutLink,
  resetCheckoutLink,
} from "../../redux/pricing-plan/action";
import SubscriptionBasicPlan from "./plans/SubscriptionBasicPlan";
import SubscriptionProPlan from "./plans/SubscriptionProPlan";
import SubscriptionEnterprisePlan from "./plans/SubscriptionEnterprisePlan";

function SubscriptionSettings({ service }) {
  const {
    pricingPlan: { checkoutSession, checkoutLoading },
    main: { userData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const showProSubscription =
    service === "myais" ||
    service === "insai" ||
    service === "alert" ||
    service === "trsim";
  const showBasicSubscription = service === "mybot";

  const [activeTab, setActiveTab] = useState(
    showProSubscription ? "pro" : "basic",
  );
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [proPlanDuration, setProPlanDuration] = useState(null);

  const buySubscriptionHandler = (e, duration) => {
    if (
      !checkoutSession ||
      checkoutLoading ||
      (activeTab === "basic" && !selectedDuration) ||
      (activeTab === "pro" && !proPlanDuration)
    ) {
      e.preventDefault();
    }

    if (!checkoutSession) {
      dispatch(
        getCheckoutLink(
          userData["cognito:username"],
          userData?.["custom:custom_username"],
          duration,
          redirectUrl,
          redirectUrl,
        ),
      );
    }
  };

  const redirectUrl = window.location.href;
  const selectDurationHandler = (duration) => {
    if (checkoutLoading) return;

    if (checkoutSession) dispatch(resetCheckoutLink());
    if (activeTab === "basic" && selectedDuration === duration) return;
    if (activeTab === "pro" && proPlanDuration === duration) return;

    if (activeTab === "basic") {
      setSelectedDuration(duration);
    } else {
      setProPlanDuration(duration);
    }
  };

  const tabItems = [
    {
      key: "basic",
      label: (
        <span>
          {" "}
          <FaCrown color={"#CD7F32"} /> Basic
        </span>
      ),
      children: (
        <SubscriptionBasicPlan
          selectedDuration={selectedDuration}
          selectDurationHandler={selectDurationHandler}
          buySubscriptionHandler={buySubscriptionHandler}
        />
      ),
    },
    {
      key: "pro",
      label: (
        <span>
          {" "}
          <FaCrown color={"#C0C0C0"} /> Pro
        </span>
      ),
      children: (
        <SubscriptionProPlan
          proPlanDuration={proPlanDuration}
          buySubscriptionHandler={buySubscriptionHandler}
          selectDurationHandler={selectDurationHandler}
        />
      ),
    },
    {
      key: "enterprise",
      label: (
        <span>
          {" "}
          <FaCrown color={"#FFD700"} /> Enterprise
        </span>
      ),
      children: <SubscriptionEnterprisePlan />,
    },
  ];

  const changeTab = (key) => {
    setSelectedDuration(null);
    setProPlanDuration(null);
    setActiveTab(key);
  };

  const tabs = showProSubscription
    ? tabItems.filter((i) => i.key !== "basic")
    : showBasicSubscription
    ? tabItems.filter((i) => i.key !== "pro")
    : tabItems;

  useEffect(() => {
    if (showProSubscription && !proPlanDuration) {
      setProPlanDuration("monthly_pro");
      // dispatch(
      //   getCheckoutLink(
      //     userData["cognito:username"],
      //     userData?.["custom:custom_username"],
      //     "monthly_pro",
      //     redirectUrl,
      //     redirectUrl,
      //   ),
      // );
    }
  }, [dispatch, proPlanDuration, redirectUrl, showProSubscription, userData]);

  useEffect(() => {
    if (showBasicSubscription && !selectedDuration) {
      setSelectedDuration("monthly");
      // dispatch(
      //   getCheckoutLink(
      //     userData["cognito:username"],
      //     userData?.["custom:custom_username"],
      //     "monthly",
      //     redirectUrl,
      //     redirectUrl,
      //   ),
      // );
    }
  }, [
    dispatch,
    redirectUrl,
    selectedDuration,
    showBasicSubscription,
    userData,
  ]);

  return (
    <div className={styles.container}>
      <h2 style={{ marginBottom: ".5rem" }}>Subscription Plans</h2>
      <Tabs
        activeKey={activeTab}
        items={tabs}
        onChange={changeTab}
        type="card"
      />
    </div>
  );
}

export default SubscriptionSettings;
