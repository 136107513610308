import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { handleBlur, handleShowPass } from "../../utility/action";
import {
  logInValidation,
  otpCodeValidation,
  otpEmailValidation,
} from "../../utility/Validation/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { notify } from "../../utility/Toastify/toastify";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import PoolData from "./AWSConfig/PoolData";
import "../../styles/pages/Auth/auth.css";
import {
  getRecaptcha,
  setToken,
  setUserData,
} from "../../redux/main/action/main";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { checkEmailException, formatUserData } from "../../utility/helper";
import axios from "axios";
import Text from "../../components/auth/Text";
import GoogleLoginButton from "./GoogleLoginButton";
import ConfirmAlert from "./ConfirmAlert";
import alert from "../../components/alerts/Alert";
import SixDigitInput from "../../components/mini-app/auth/SixDigitInput";
import { FiEdit } from "react-icons/fi";
import ReactLoading from "react-loading";
import { TbReload } from "react-icons/tb";
import RecaptchaModal from "./login/RecaptchaModal";

function LoginPage() {
  const { recaptchaData, recaptchaStatus } = useSelector(
    (state) => state?.main,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [googleLoading, setGoogleLoading] = useState(false);
  const [code, setCode] = useState("");
  const [showRecaptchaModal, setShowRecaptchaModal] = useState(false);

  //google authentication integrated with AWS

  Amplify.configure({
    Auth: {
      identityPoolId: "us-east-1:dad77690-7bc3-4d24-bfc8-9a5bc43f1f6a",
      region: "us-east-1",
    },
  });

  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [pass, setPass] = useState("");
  const [formData, setFormData] = useState({});
  //btn loading
  const [loading, setLoading] = useState(false);
  const [loginType, setLoginType] = useState("otp");
  const [otpStep, setOtpStep] = useState(1);
  const [isNotTyping, setIsNotTyping] = useState(false);

  const validationSchema =
    loginType === "email"
      ? logInValidation
      : otpStep === 1
      ? otpEmailValidation
      : otpCodeValidation;

  // validation
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  //form submit function
  const onsubmit = async (data) => {
    if (loginType === "otp") {
      setUserEmail(data.email);
      setLoading(true);
      if (otpStep === 1) {
        await axios
          .post(
            "https://weu658vyjj.execute-api.ap-southeast-2.amazonaws.com/dev/api",
            {
              operation_mode: "send_verification_code",
              email: checkEmailException(data?.email),
            },
          )
          .then((res) => {
            notify("Verification code sent", "success");
            setOtpStep(2);
          })
          .catch((err) => {
            alert(null, err?.response?.data?.message, "error", {
              cancel: "Ok",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }

      if (otpStep === 2) {
        setCode(data?.code);
        await axios
          .post(
            "https://weu658vyjj.execute-api.ap-southeast-2.amazonaws.com/dev/api",
            {
              operation_mode: "verify_verification_code",
              email: checkEmailException(data?.email),
              verification_code: data?.code,
            },
          )
          .then((res) => {
            console.log(res);
            const {
              accessToken: { jwtToken },
            } = res?.data;
            dispatch(setToken(jwtToken));
            dispatch(setUserData(formatUserData(res?.data?.accessToken)));
            localStorage.setItem(
              "aws-amplify-federatedInfo",
              JSON.stringify({ token: jwtToken }),
            );
            axios.defaults.headers.common["Authorization"] = `${jwtToken}`;
            navigate("/welcome");
          })
          .catch((err) => {
            alert(null, err?.response?.data?.message, "error", {
              cancel: "Ok",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }

    if (loginType === "email") {
      // if (getValues()?.recaptcha !== localStorage.getItem("recAnswer")) return;
      setFormData(data);
      setShowRecaptchaModal(true);
    }
  };

  useEffect(() => {
    dispatch(getRecaptcha());
  }, []);

  return (
    <div className="limiter">
      <div className="container-login100">
        {/*<Logo />*/}
        <div className="content">
          <Text />
          <div className="wrap-login100">
            <div>
              <form
                className="login100-form validate-form"
                onSubmit={handleSubmit(onsubmit)}
              >
                <h4 className="form-title">
                  Manage Your Crypto Investments - Sign In Here
                </h4>
                <ul className="login-tab">
                  <li
                    className={`login-option ${
                      loginType === "otp" ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      if (loading) return;
                      setLoginType("otp");
                    }}
                  >
                    Email / OTP
                  </li>
                  <li
                    className={`login-option ${
                      loginType === "email" ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      if (loading) return;
                      setLoginType("email");
                    }}
                  >
                    Email / Password
                  </li>
                </ul>

                {loginType === "otp" && (
                  <>
                    {otpStep === 1 && (
                      <>
                        <div className="wrap-input100 validate-input">
                          <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                              <input
                                className="input100"
                                type="text"
                                {...field}
                                onBlur={(e) => handleBlur(e)}
                                autoComplete="email"
                                placeholder="Email"
                              />
                            )}
                          />
                        </div>

                        {errors.email && (
                          <span className="error-text">
                            {errors.email.message}
                          </span>
                        )}

                        <Button
                          ButtonText={"Send Code"}
                          className={"login"}
                          loading={loading}
                          type="submit"
                        />

                        <GoogleLoginButton setLoading={setGoogleLoading} />

                        <div className="text-center p-t-20">
                          <span className="txt1">Don’t have an account?</span>
                          <Link className="txt2" to="/auth/signup">
                            Create Account
                          </Link>
                        </div>
                        <div className="text-center">
                          <Link className="txt2" to="/auth/forgotPassword">
                            Forgot password
                          </Link>
                        </div>
                      </>
                    )}

                    {otpStep === 2 && (
                      <>
                        <div
                          className="wrap-input100 validate-input"
                          style={{ borderBottom: "none" }}
                        >
                          <label
                            style={{
                              color: "white",
                              marginBottom: "1rem",
                              display: "block",
                            }}
                          >
                            Enter the 6-digit code sent to your email
                          </label>
                          <Controller
                            name="code"
                            control={control}
                            render={({ field }) => (
                              <SixDigitInput
                                value={code}
                                onChange={(value) => {
                                  field.onChange(value);
                                  setCode(value);
                                }}
                                onBlur={(e) => {
                                  field.onBlur(e);
                                  handleBlur(e);
                                  setIsNotTyping(true);
                                }}
                                onFocus={() => setIsNotTyping(false)}
                              />
                            )}
                          />
                        </div>
                        {errors.code && isNotTyping && (
                          <span className="error-text">
                            {errors.code.message}
                          </span>
                        )}

                        <Button
                          ButtonText={"Login"}
                          className={"login"}
                          loading={loading}
                          type="submit"
                        />

                        <div
                          className="edit-email-btn"
                          onClick={() => {
                            setOtpStep(1);
                            setCode("");
                            setUserEmail("");
                          }}
                        >
                          <FiEdit /> Edit email
                        </div>
                      </>
                    )}
                  </>
                )}

                {loginType === "email" && (
                  <>
                    <div className=" wrap-input100 validate-input`">
                      <input
                        className="input100 has-val"
                        type={"text"}
                        {...register("email")}
                        onBlur={(e) => handleBlur(e)}
                        name="email"
                        autoComplete="email"
                      />
                      <span
                        className="focus-input100"
                        data-placeholder={"Email"}
                      ></span>
                    </div>
                    {errors.email && (
                      <span className="error-text">{errors.email.message}</span>
                    )}

                    <div className=" wrap-input100 validate-input">
                      <span className="btn-show-pass">
                        <i
                          className="far fa-eye"
                          onClick={(e) => handleShowPass(e)}
                        ></i>
                      </span>
                      <input
                        className="input100 has-val"
                        type={"password"}
                        {...register("pass")}
                        onBlur={(e) => handleBlur(e)}
                        name="pass"
                        autoComplete="current-password"
                      />

                      <span
                        className="focus-input100"
                        data-placeholder={"Password"}
                      ></span>
                    </div>
                    {errors.pass && (
                      <span className="error-text">{errors.pass.message}</span>
                    )}

                    {/*<div*/}
                    {/*  className=" wrap-input100 validate-input"*/}
                    {/*  style={*/}
                    {/*    recaptchaStatus !== "ready"*/}
                    {/*      ? { borderBottom: "none" }*/}
                    {/*      : {}*/}
                    {/*  }*/}
                    {/*>*/}
                    {/*  {recaptchaStatus === "loading" ? (*/}
                    {/*    <>*/}
                    {/*      <p*/}
                    {/*        style={{*/}
                    {/*          color: "white",*/}
                    {/*          display: "flex",*/}
                    {/*          justifyContent: "center",*/}
                    {/*          gap: ".5rem",*/}
                    {/*        }}*/}
                    {/*      >*/}
                    {/*        <ReactLoading*/}
                    {/*          type={"bars"}*/}
                    {/*          color="#fff"*/}
                    {/*          height={30}*/}
                    {/*          width={26}*/}
                    {/*        />{" "}*/}
                    {/*        /!*Loading reCAPTCHA...*!/*/}
                    {/*      </p>*/}
                    {/*    </>*/}
                    {/*  ) : recaptchaStatus === "error" ? (*/}
                    {/*    <p*/}
                    {/*      className="refreshBtn"*/}
                    {/*      onClick={(e) => {*/}
                    {/*        e.stopPropagation();*/}
                    {/*        dispatch(getRecaptcha());*/}
                    {/*      }}*/}
                    {/*    >*/}
                    {/*      Refresh reCAPTCHA*/}
                    {/*      <TbReload size={18} />*/}
                    {/*    </p>*/}
                    {/*  ) : (*/}
                    {/*    <>*/}
                    {/*      <TbReload*/}
                    {/*        className="reload-btn"*/}
                    {/*        size={22}*/}
                    {/*        onClick={(e) => {*/}
                    {/*          e.stopPropagation();*/}
                    {/*          dispatch(getRecaptcha());*/}
                    {/*        }}*/}
                    {/*      />*/}
                    {/*      <input*/}
                    {/*        className="input100 has-val recaptcha-input"*/}
                    {/*        type={"number"}*/}
                    {/*        {...register("recaptcha")}*/}
                    {/*        onBlur={(e) => handleBlur(e)}*/}
                    {/*        name="recaptcha"*/}
                    {/*      />*/}
                    {/*      <span*/}
                    {/*        className="focus-input100"*/}
                    {/*        data-placeholder={recaptchaData?.question}*/}
                    {/*      ></span>*/}
                    {/*    </>*/}
                    {/*  )}*/}
                    {/*</div>*/}
                    {/*{errors.recaptcha && (*/}
                    {/*  <span className="error-text">*/}
                    {/*    {errors.recaptcha.message}*/}
                    {/*  </span>*/}
                    {/*)}*/}

                    <Button
                      ButtonText={"LogIn"}
                      className={"login"}
                      loading={loading || googleLoading}
                    />
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <RecaptchaModal
        open={showRecaptchaModal}
        onClose={() => setShowRecaptchaModal(false)}
        data={formData}
      />
    </div>
  );
}

export default LoginPage;
